@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.App {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bgTransparent {
  background: transparent;
  backdrop-filter: blur(100px);
}

.containFull {
  width: 100%;
  height: 100%;
}

.centeralign {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.translateY {
  transform: translate(-0%, -50%);
}

.translateX {
  transform: translate(-50%, 0%);
}

.horizontalCenterAlign {
  left: 50%;
  transform: translate(-50%);
}

.verticalCenterAlign {
  top: 50%;
  transform: translate(0%, -50%);
}

.horizontalSeperator {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
}

.boxShadow {
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.16);
}
.clashdisplay_semibold {
  font-family: "ClashDisplay-Semibold";
}

.clashdisplay_bold {
  font-family: "ClashDisplay-Bold";
}

.dmSerif_italic_blue {
  color: #3171f6;
  font-style: italic;
  font-family: "DM Serif Display";
}

.dmSerifRegularItalic {
  font-family: "DM Serif Display";
  font-weight: 400;
  font-style: italic;
}

.mobileOnly,
.desktopOnly {
  display: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.coming {
  animation: fadeIn 0.5s ease-in;
}

.out {
  animation: fadeOut 0.5s ease-out !important;
}

.boxSh {
  background: #fcfcfc;
  box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.16);
  border-radius: 16px;
}

.backToTopShadow {
  box-shadow: 0px 4px 32px 0px rgba(30, 30, 30, 0.32);
}

.font_bold_52_28 {
  font-size: 52px;
  font-weight: 700;
  line-height: 120%; /* 62.4px */
  letter-spacing: -0.56px;
}

.font_bold_32 {
  font-size: 32px;
  font-weight: 700;
  line-height: 140%; /* 44.8px */
  letter-spacing: -0.64px;
}

.font_bold_28_21 {
  font-size: 28px;
  font-weight: 700;
  line-height: 115%; /* 32.2px */
  letter-spacing: -0.56px;
}

.font_bold_24_18 {
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.48px;
}

.font_bold_24 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
}

.font_bold_21 {
  font-size: 21px;
  font-weight: 700;
  line-height: 126%; /* 26.46px */
  letter-spacing: -0.48px;
}

.font_bold_18 {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}

.font_bold_16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.font_semibold_120_62 {
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 74.4px */
  letter-spacing: -2.48px;
}

.font_semibold_72_40 {
  font-size: 72px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 86.4px */
  letter-spacing: -2.88px;
}

.font_semibold_72_32 {
  font-size: 100px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 74.4px */
  letter-spacing: -2.48px;
}

.font_semibold_60_52_40 {
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 72px */
  letter-spacing: -2.4px;
}

.font_semibold_60 {
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 72px */
  letter-spacing: -2.4px;
}

.font_semibold_52_32 {
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 62.4px */
  letter-spacing: -2.08px;
}

.font_semibold_52_28 {
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 62.4px */
  letter-spacing: -1.04px;
}

.font_semibold_40_30 {
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.8px;
}

.font_semibold_40_24 {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0em;
}


.font_semibold_32 {
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.font_semibold_32_16 {
  font-size: 32px;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -0.64px;
}

.font_semibold_32_24 {
  font-size: 32px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: -0.64px;
}

.font_semibold_28 {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0em;
}

.font_semibold_28_24 {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0em;
}

.font_semibold_28_24_24 {
  font-size: 28px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0em;
}

.font_semibold_24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: 0em;
}

.font_semibold_24_21 {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.48px;
}

.font_semibold_21 {
  font-size: 21px;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: -0.84px;
}

.font_semibold_21_18 {
  font-size: 21px;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: -0.84px;
}

.font_semibold_21_16 {
  font-size: 21px;
  font-weight: 600;
  line-height: 120%; /* 25.2px */
  letter-spacing: -0.42px;
}

.font_semibold_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.font_semibold_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.font_semibold_13 {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.52px;
}
.font_semibold_12 {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.font_semibold_10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.font_medium_52 {
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 62.4px */
  letter-spacing: -2.08px;
}

.font_medium_52_32 {
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 62.4px */
  letter-spacing: -2.08px;
}

.font_medium_52_32_32 {
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 62.4px */
  letter-spacing: -2.08px;
}

.font_medium_40 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 48px */
  letter-spacing: -1.6px;
}

.font_medium_40_32 {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 48px */
  letter-spacing: -1.6px;
}

.font_medium_32 {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -1.28px;
}

.font_medium_21_18 {
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.42px;
}

.font_medium_21 {
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 29.4px */
  letter-spacing: -0.42px;
}

.font_medium_18 {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.72px;
}

.font_medium_16_13 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
}

.font_medium_16 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
}

.font_medium_14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.font_medium_16_14 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
}

.font_medium_13 {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.26px;
}

.font_medium_12 {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 169.231% */
  letter-spacing: -0.24px;
}

.font_medium_10 {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
  letter-spacing: -0.2px;
}

.font_normal_52 {
  font-size: 52px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -2.08px;
}

.font_normal_52_32 {
  font-size: 52px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -2.08px;
}

.font_normal_43_24 {
  font-size: 43px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.86px;
}

.font_normal_40 {
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -1.6px;
}

.font_normal_40_32 {
  font-size: 40px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -1.6px;
}

.font_normal_34 {
  font-size: 34px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.96px;
}

.font_normal_32_24 {
  font-size: 32px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.64px;
}

.font_normal_24 {
  font-size: 24px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.96px;
}

.font_normal_24_16 {
  font-size: 24px;
  font-weight: 400;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.96px;
}

.font_normal_21_16 {
  font-size: 21px;
  font-weight: 400;
  line-height: 140%; /* 29.4px */
  letter-spacing: 0em;
}

.font_normal_21 {
  font-size: 21px;
  font-weight: 400;
  line-height: 130%; /* 27.3px */
}

.font_normal_18_16 {
  font-size: 18px;
  font-weight: 400;
  line-height: 140%; /* 25.2px */
  letter-spacing: -0.36px;
}

.font_normal_16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.64px;
}

.font_normal_16_13 {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_normal_13 {
  font-size: 13px;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
  letter-spacing: -0.26px;
}

.font_normal_12 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_normal_12_9 {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.font_normal_10 {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px; /* 180% */
  letter-spacing: -0.2px;
}

.font_light_40_32 {
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%; /* 44px */
  letter-spacing: -2px;
}

@media (min-width: 1024px) {
  .desktopOnly {
    display: flex;
  }
}

@media (min-width: 640px) and (max-width: 1024px) {
  .font_semibold_60_52_40 {
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 62.4px */
    letter-spacing: -2.08px;
  }
}

@media (max-width: 1024px) {
  .font_semibold_120_62 {
    font-size: 62px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 74.4px */
    letter-spacing: -2.48px;
  }

  .font_semibold_72_40 {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    letter-spacing: -1.6px;
  }

  .font_semibold_72_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 38.4px */
    letter-spacing: -1.28px;
  }

  .font_semibold_28_24_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
  }

  .font_semibold_24_21 {
    font-size: 21px;
    font-weight: 600;
    line-height: 120%; /* 25.2px */
    letter-spacing: -0.42px;
  }

  .font_semibold_21_18 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: -0.72px;
  }

  .font_semibold_21_16 {
    font-size: 16px;
    font-weight: 600;
    line-height: 120%; /* 25.2px */
    letter-spacing: -0.32px;
  }

  .font_medium_52_32_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -1.28px;
  }

  .font_normal_24_16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: -0.64px;
  }
}

@media (max-width: 1023px) {
  .mobileOnly {
    display: block;
  }

  .font_bold_52_28 {
    font-size: 28px;
    font-weight: 700;
    line-height: 115%; /* 32.2px */
    letter-spacing: -0.56px;
  }

  .font_bold_28_21 {
    font-size: 21px;
    font-weight: 700;
    line-height: 126%; /* 26.46px */
    letter-spacing: -0.42px;
  }

  .font_bold_24_18 {
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
  }

  .font_semibold_60_52_40 {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 48px */
    letter-spacing: -1.6px;
  }

  .font_semibold_52_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 46.2px */
    letter-spacing: -1.32px;
  }

  .font_semibold_52_28 {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 62.4px */
    letter-spacing: -0.56px;
  }

  .font_semibold_40_30 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 133.333% */
    letter-spacing: -0.6px;
  }

  .font_semibold_40_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: 0em;
  }

  .font_semibold_32_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: -0.48px;
  }

  .font_semibold_32_16 {
    font-size: 16px;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.64px;
  }

  .font_semibold_28_24 {
    font-size: 24px;
    font-weight: 600;
    line-height: 120%; /* 28.8px */
    letter-spacing: 0em;
  }

  .font_medium_52_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -1.28px;
  }

  .font_medium_40_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 38.4px */
    letter-spacing: -1.28px;
  }

  .font_medium_21_18 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 21.6px */
    letter-spacing: -0.36px;
  }


  .font_medium_16_14 {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
  }

  .font_medium_16_13 {
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px; /* 137.5% */
    letter-spacing: -0.26px;
  }

  .font_normal_52_32 {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -1.28px;
  }

  .font_normal_43_24 {
    font-size: 24px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.48px;
  }

  .font_normal_40_32 {
    font-size: 32px;
    font-weight: 400;
    line-height: 120%; /* 38.4px */
    letter-spacing: -1.28px;
  }

  .font_normal_32_24 {
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.48px;
  }

  .font_normal_21_16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 29.4px */
    letter-spacing: 0em;
  }

  .font_normal_18_16 {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.32px;
  }

  .font_normal_16_13 {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
  }

  .font_normal_12_9 {
    font-size: 9px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .font_light_40_32 {
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 110%; /* 35.2px */
    letter-spacing: -2px;
  }
}
